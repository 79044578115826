import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { List } from "../components/List";
import { StaticImage } from "gatsby-plugin-image";
import { HandleSlider } from "../components/HandleSlider";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const p1 = [
  {
    txt: (
      <p>
        <strong>„</strong>
        <br />
        Firma JB Multimedia Jerzy Bielecki Biała Podlaska to firma godna
        polecenia. Szybka profesjonalna obsługa sprawia, że współpraca z firmą
        to czysta przyjemność. Pracownicy to ludzie doskonale zorientowani w
        realiach rynku. <br /> <strong> ”</strong>
      </p>
    ),
  },
  {
    // pImg: <StaticImage className="desktopImg" src="../assets/images/bg2.jpg" />,
  },
];
const p2 = [
  {
    txt: (
      <p>
        <strong>„</strong>
        <br />
        Z usług świadczonych przez firmę JB Multimedia Jerzy Bielecki z siedzibą
        w Białej Podlaskiej jesteśmy usatysfakcjonowani. Wynajmowany sprzęt jest
        dobrej jakości. Zdarzające się sporadycznie awarie wynikające z
        eksploatacji urządzeń usuwane są bardzo szybko przez serwis firmy, co
        jest wyjątkowo istotne dla sprawnego funkcjonowania biura ZDP w Radzyniu
        Podlaskim. Ponadto firma proponuje korzystne ceny za swoje usługi. Mając
        powyższe na uwadze polecamy korzystanie z usług firmy JB Multimedia
        Jerzy Bielecki.
        <br />
        <strong> ”</strong>
      </p>
    ),
  },
];
const p3 = [
  {
    txt: (
      <p>
        <strong>„</strong>
        <br />
        Bardzo duży wybór sprzętu, każdy na pewno znajdzie coś dla siebie.
        Fachowa pomoc obsługi, miłe i kompetentne podejście do klienta - widać
        profesjonalizm. Po zakupach zawsze bezproblemowy kontakt ze sprzedawcą w
        razie jakichkolwiek pytań. Dziękujemy za dotychczasową współpracę.
        <br />
        <strong> ”</strong>
      </p>
    ),
  },
];
const p4 = [
  {
    txt: (
      <p>
        <strong>„</strong>
        <br />
        Problemy zgłaszane są szybko realizowane, materiały eksploatacyjne są
        dobrej jakości, a urządzenia dobrej marki i jakości. Jesteśmy zadowoleni
        z usług.
        <br />
        <strong> ”</strong>
      </p>
    ),
  },
];
const p5 = [
  {
    txt: (
      <p>
        <strong>„</strong>
        <br />
        Jesteśmy bardzo zadowoleni z dotychczasowej współpracy z Państwa firmą.
        Cenimy sobie profesjonalizm, szybką reakcję na wszelkie problemy i
        doskonałą jakość świadczonych usług. Nasza Firma to Partner Biznesowy,
        który zapewnia dla nas sprzęt biurowy najwyższej klasy. Współpraca do
        wielu lat przebiega bardzo sprawnie i bez problemów.
        <br />
        <strong> ”</strong>
      </p>
    ),
  },
];
const p6 = [
  {
    txt: (
      <p>
        <strong>„</strong>
        <br />
        Współpraca z Państwem układa się pomyślnie, jesteśmy zadowolenie z
        Państwa usług. W razie problemów technicznych i awarii sprzętu szybko
        realizujecie nasze zlecenia.
        <br />
        <strong> ”</strong>
      </p>
    ),
  },
];

const pageData = {
  header: "O nas",
  mainTitle: "JB multimedia",
  subtitle: "Poznajmy się.",
};

const realizations = {
  header: "Nasze realizacje",
  title: "Sprawdź, jakie ",
  subtitle: "projekty zrealizowaliśmy",
  paragraph:
    "Nasza firma łączy nie tylko doradztwo, dostawę najnowocześniejszego sprzętu, ale także kompleksowe usługi montażowe, dzięki którym nasi klienci mogą cieszyć się doskonałą jakością i niezawodnością w swoich projektach.",
};

const slidesContent = [
  {
    title: "SILVER PARTNER",
    text: "w zakresie sprzedaży urządzeń oraz materiałów eksploatacyjnych",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/Brother_logo.svg"
        alt="brother logo"
      />
    ),
  },
  {
    title: "WYRÓŹNIENIE 25 lat Canon Polska",
    text: "w podziękowaniu za wieloletnią współpracę",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/Canon_wordmark.svg"
        alt="canon logo"
      />
    ),
  },
  {
    title: "PODZIĘKOWANIE",
    text: "za dotychczasową współpracę, poświęcony czas i serce",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/Caritas.svg"
        alt="caritas logo"
      />
    ),
  },
  {
    title: "Samsung Channel Meeting 2019",
    text: "Wynik sprzedaży dysków SSD oraz współpracę w roku 2018",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/samsung.svg"
        alt="samsung logo"
      />
    ),
  },

  {
    title: "Partner roku 2020",
    text: "w zakresie dystrybucji sprzętu multimedialnego",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/Epson_logo.svg"
        alt="epson logo"
      />
    ),
  },
  {
    title: "CERTYFIKAT Sales Master",
    text: "szkolenie z zakresu kluczowowych kompetencji sprzedawcy ",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/logo_saport_levelu.jpg"
        alt="saport_levelu logo"
      />
    ),
  },
  {
    title: "PLATINUM Business Monitors",
    text: "w obszarze profesjonalnych monitorów LCD",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/samsung.svg"
        alt="samsung logo"
      />
    ),
  },

  {
    title: "SILVER PARTNER 2023",
    text: "I-SENSYS laser Hardware & I-SENSYS Laser Consumables",
    imgBox: (
      <StaticImage
        src="../assets/images/awards/Canon_wordmark.svg"
        alt="canon logo"
      />
    ),
  },
];

const AboutPage = () => {
  const [active, setActive] = useState("hidden");
  const [status, setStatus] = useState(true);
  const [showMore, setShowMore] = useState("Pokaż więcej realizacji");

  const handleClick = () => {
    if (status) {
      setStatus(false);
      setActive("active");
      setShowMore("Pokaż mniej");
    } else {
      setStatus(true);
      setActive("hidden");
      setShowMore("Pokaż więcej realizacji");
    }
  };
  return (
    <>
      <PageInfo
        header={pageData.header}
        mainTitle={pageData.mainTitle}
        // date={pageData.date}
        subtitle={pageData.subtitle}
      />
      <Layout>
        <section className="aboutUs page">
          <div className="aboutUs">
            <div className="aboutUsPage ">
              <div className="aboutUs-container">
                <div className="imgContainer">
                  <StaticImage src="../assets/images/about/abUs.jpg" />
                  <div className="square"></div>
                </div>

                <div className="aboutUs txt first">
                  <div id="abUs" className="anchor"></div>
                  <div className="aboutUs txt-container">
                    <h4>Kim jesteśmy</h4>
                    <h2>
                      Poznaj swojego <br /> <strong>Partnera IT</strong>
                    </h2>
                  </div>
                  <p className="headerTxt">
                    Działamy od 1996 roku i robimy to, co potrafimy najlepiej.
                    <b>
                      {" "}
                      Projektujemy i dostarczamy rozwiązania informatyczne,
                      które są „szyte na miarę"
                    </b>
                    . Kompleksowe, spersonalizowane i adekwatne do oczekiwań.
                  </p>
                  <p className="headerTxt">
                    Dzięki szerokiej ofercie sprzętu informatycznego dostępnego
                    w dużej mierze od ręki oraz gamie usług dodatkowych,
                    zdobyliśmy zaufanie naszych Partnerów, zostając{" "}
                    <strong>liderem rynku IT we wschodniej Polsce</strong>.
                    Naszą ofertę kierujemy do klientów indywidualnych,
                    biznesowych i instytucjonalnych.{" "}
                  </p>
                </div>
              </div>
              <div className="aboutUs-container">
                <div className="aboutUs txt first">
                  <p>
                    Różnorodność rozwiązań informatycznych oraz ich
                    kompleksowość odzwierciedla również profil naszej firmy i
                    jej struktura. Rozwinęliśmy{" "}
                    <b>
                      sieć stacjonarnych salonów sprzedaży oraz sklep
                      internetowy{" "}
                      <Link style={{ color: "#d71920" }} to="https://jbm.pl">
                        {" "}
                        jbm.pl
                      </Link>
                      , działy sprzedaży detalicznej, hurtowej, oraz dedykowanej
                      dla Klienta Biznesowego
                    </b>
                    . Posiadamy również swój własny punkt serwisowy, dzięki
                    czemu jesteśmy w stanie zapewnić kompleksową opiekę nad
                    sprzętem w czasie trwania gwarancji, jak i po jej
                    zakończeniu.
                  </p>
                  <p>
                    Stale poszerzamy swoją ofertę produktową oferując w
                    sprzedaży detalicznej i internetowej produkty z kategorii
                    <b> IT, RTV i AGD</b>. Stawiamy także na profesjonalizm i
                    wiedzę naszego zespołu oraz produkty i rozwiązania wyłącznie
                    sprawdzonych, renomowanych marek.{" "}
                    <b>
                      Wieloletnia współpraca z największymi i najbardziej
                      rozpoznawalnymi na rynku IT producentami
                    </b>{" "}
                    została wielokrotnie doceniona, przez co znaleźliśmy się w
                    gronie ich najbliższych Partnerów. Możemy również poszczycić
                    się kilkukrotnie zdobytym tytułem{" "}
                    <strong>Gazeli Biznesu</strong>. Świadczy to o tym, że
                    zaliczamy się do grona najdynamiczniej rozwijających się
                    firm w Polsce.
                  </p>
                </div>
                <div className="imgContainer">
                  <StaticImage src="../assets/images/about/business1a.jpg" />
                  <div className="square"></div>
                </div>
              </div>
              <div className="realizations-container">
                <div id="realizations" className="anchor realizations" />
                <PageInfo
                  header={realizations.header}
                  title={realizations.title}
                  subtitle={realizations.subtitle}
                  paragraph={realizations.paragraph}
                />
                <div className="relizations-container-wrapper">
                  <div className="realizations-element">
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/church.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Montaże w kościołach</h2>
                          <p>
                            Wdrożyliśmy w kilku kościołach system multimedialny
                            w oparciu o oprogramowanie „Slajd”. System cechuje
                            wyjątkowo prosta i intuicyjna obsługa, wspomagana
                            monitorami dotykowymi.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/projektujemy.svg" />
                                <span>Projekt</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images//advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_szpital.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Komputery dla szpitala w Białej Podlaskiej</h2>
                          <p>
                            Dostawa 63 szt. zestawów komputerowych, laptopa,
                            telewizora, drukarek i oprogramowania MS Word, MS
                            Office.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/projektujemy.svg" />
                                <span>Projekt</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images//advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element ${active}`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_1101_zestawow.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>
                            1101 zestawów komputerowych dla gmin Lubelszczyzny
                          </h2>
                          <p>
                            W ramach projektu zmniejszenia poziomu wykluczenia
                            cyfrowego w 10 gminach północnej Lubelszczyzny
                            dostarczyliśmy 1101 zestawów komputerowych.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/szkolimy.svg" />
                                <span>Szkolenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element ${active}`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_akadiemia_bialska.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Akademia Bialska</h2>
                          <p>
                            Zaopatrujemy bialską uczelnie w najnowocześniejsze
                            urządzenia, począwszy od komputerów typu All in One
                            o zwiększonej mocy, monitory interaktywne
                            Touchscreen AVTEK (nawet do 98 cali!), systemy
                            nagłośnienia, kamery do wideokonferencji oraz gogle
                            do rozszerzonej rzeczywistości Microsoft Hololens 2.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/szkolimy.svg" />
                                <span>Szkolenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element ${active}`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_Projekt Kraszewski.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Projekt Kraszewski - Komputery dla bibliotek</h2>
                          <p>
                            To wyposażenie Gminnych Bibliotek Publicznych
                            (Kąkolewnica wraz z filiami - Polskowola, Turów,
                            Brzozowica Duża, Olszewnica), oraz GBP w Sosnówce w
                            nowoczesne komputery All in One, drukarki, roboty
                            edukacyjne, gogle VR, pomoce dydaktyczne
                            poprawiające funkcjonalność biblioteki.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/projektujemy.svg" />
                                <span>Projekt</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images//advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element ${active}`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_laboratoria_przyszlosci.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Laboratoria Przyszłości </h2>
                          <p>
                            Dostarczenie do kilkunastu szkół w regionie w
                            urządzenia spełniające wymogi programu Laboratoria
                            Przyszłości takie jak: drukarki 3d, mikrokontrolery,
                            lutownice, kamery, aparaty wraz ze statywami,
                            gimbale mikrofony, mikrporty oraz oświetlenie do
                            realizacji nagrań.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/szkolimy.svg" />
                                <span>Szkolenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element ${active}`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_Gminne Centrum Kultury w Konstantynowie.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Gminne Centrum Kultury w Konstantynowie</h2>
                          <p>
                            Wyposażenie sali w lampy oświetleniowe, grabery,
                            aparaty, prompter, tła fotograficzne, oprogramowanie
                            do obróbki zdjęć i filmów.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/szkolimy.svg" />
                                <span>Szkolenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`realizations-element ${active}`}>
                    <div className="realizations-element-wrapper">
                      <StaticImage src="../assets/images/realisations/projekty_aktywna_tablica.jpg" />
                      <div className="square"></div>
                      <div className="txt">
                        <div className="">
                          <h2>Aktywna Tablica </h2>
                          <p>
                            Dostawa monitorów interaktywnych w szkołach wraz z
                            montażem uwzględniającym maskowanie okablowania,
                            przyłącza HDMI i USB, dzięki czemu podłączanie
                            urządzeń wewnętrznych nie stanowi żadnego problemu.
                            Montaże urządzeń kończą się kompleksowym szkoleniem.
                          </p>
                          <div className="activities">
                            <span className="activities-title">
                              Zakres działań
                            </span>
                            <div className="activities-wrapper">
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/wdrazamy.svg" />
                                <span>Wdrożenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/szkolimy.svg" />
                                <span>Szkolenie</span>
                              </div>
                              <div className="activities-element">
                                <StaticImage src="../assets/images/advantages/doradzamy.svg" />
                                <span>Doradztwo</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn-red" onClick={handleClick}>
                  {showMore}
                </button>
              </div>
              <div className="txt">
                <div id="aww" className="anchor" />
                <div className="txt-container">
                  <h4>Nagrody i wyróżnienia</h4>
                  <h2>
                    Sprawdź nasze <br /> <strong>kompetencje</strong>
                  </h2>
                </div>
              </div>
              <HandleSlider slidesContent={slidesContent} />
              <div className="opinion">
                <div className="txt">
                  <div id="opinions" className="anchor" />
                  <div className="txt-container">
                    <h4>Opinie</h4>
                    <h2>
                      Co mówią <br /> <strong>o nas Klienci</strong>
                    </h2>
                  </div>
                </div>
                <div className="opinion-list-wrapper">
                  <List
                    header={"SZKOŁA PODSTAWOWA W ZALESIU "}
                    paragraph={p1[0].txt}
                  />
                  <List
                    header="ZARZĄD DRÓG POWIATOWYCH W RADZYNIU PODLASKIM"
                    paragraph={p2[0].txt}
                  />
                  <List
                    header="PRZEDSZKOLE NIEPUBLICZNE W MIĘDZYRZECU PODLASKIM „AKADEMIA UŚMIECHU”"
                    paragraph={p3[0].txt}
                  />
                  <List
                    header="BIALSKIE CENTRUM KULTURY "
                    paragraph={p4[0].txt}
                  />
                  <List
                    header="KOMENDA MIEJSKA PAŃSTWOWEJ STRAŻY POŻARNEJ W BIAŁEJ PODLASKIEJ"
                    paragraph={p5[0].txt}
                  />
                  <List
                    header="SPOŁECZNA SZKOŁA PODSTAWOWA  W BIAŁEJ PODLASKIEJ"
                    paragraph={p6[0].txt}
                  />
                </div>
              </div>

              {/* <div className="gallery">
              <div className="gallery-container">
                <AbUsGallery />
              </div>
            </div> */}
            </div>
            {/* <div className="about-realizations">
            <div className="about-realizations-wrapper">
              <h2 id="realizations">Realizacje</h2>
              <div className="realizations-txt">
                <p>
                  JB Multimedia oferuje kompleksowe rozwiązania informatyczne
                  dla klientów indywidualnych, przedsiębiorstw i instytucji
                  publicznych.
                </p>
                <p>
                  Efektem naszej wieloletniej działalności są zrealizowane przez
                  nas kompleksowo projekty opierające się na doborze sprzętu
                  informatycznego, profesjonalnym doradztwie w wyborze
                  optymalnych rozwiązań, prezentacji i dostarczeniu produktu,
                  montażu i serwisie.
                </p>
              </div>
              <div className="adv-list-wrapper">
                <List
                  header="Montaże w kościołach"
                  paragraph={[p1[0].pTxt, p1[1].pImg]}
                />
                <List
                  header="Dostawa i montaż dla szkół"
                  paragraph={[p2[0].pTxt, p1[1].pImg]}
                />
                <List
                  header="Komputery dla UM Lubartów"
                  paragraph={[p3[0].pTxt, p1[1].pImg]}
                />
                <List
                  header="Wyposażenie przedszkoli"
                  paragraph={[p4[0].pTxt, p1[1].pImg]}
                />
                <List
                  header="1101 zestawów komputerowych dla gmin Lubelszczyzny"
                  paragraph={[p5[0].pTxt, p1[1].pImg]}
                />
                <List
                  header="Dostawa i montaż dla Starostwa Powiatowego"
                  paragraph={[p6[0].pTxt, p1[1].pImg]}
                />
                <List
                  header="Komputery dla szpitala w Białej Podlaskiej"
                  paragraph={[p7[0].pTxt, p1[1].pImg]}
                />
              </div>
            </div>
          </div> */}
            {/* <div className="awards">
            <div className="awards-container">
              <div className="awards-container-wrapper">
                <h2 id="aww">Nagrody i wyróżnienia</h2>
              </div>
              <AwGallery />
            </div>
          </div> */}
          </div>
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo
    title="O firmie JB Multimedia"
    description='Projektujemy i dostarczamy rozwiązania informatyczne, które są "szyte na miarę"'
  />
);

export default AboutPage;
